import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import ContactForm from '../components/ContactForm';

export default () => (
  <DefaultLayout
    pageTitle="Contact"
    pageDescription="Contact &yet about design and development services"
    bodyClass="contact"
  >
    <div className="container">
      <section className="grid-flex-container">
        <div className="grid-flex-cell">
          <h1>Get in touch</h1>
          <p>
            We’d love to hear what you’re working on and how we might be able to
            help. Shoot us an email at{' '}
            <a href="mailto:contact@andyet.com">contact@andyet.com</a> or fill
            out this handy form.
          </p>
        </div>
        <div className="grid-flex-cell">
          <ContactForm />
        </div>
      </section>
    </div>
  </DefaultLayout>
);
